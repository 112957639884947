import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import JournalCard from "../components/Blog/JournalCard";

import Pagination from "../components/Blog/Pagination";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const BlogPages = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const { meta, social } = useSiteMetadata();

  return (
    <Layout>
      <Seo
        title={
          meta.blogName +
          (pageContext.pageNumber > 0
            ? meta.sitePageTitleSep +
              "Page " +
              (pageContext.pageNumber + 1) +
              " of " +
              pageContext.numberOfPages
            : "") +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={
          meta.siteUrl +
          "/blog/" +
          (pageContext.pageNumber ? "page/" + (pageContext.pageNumber + 1) : "")
        }
        description={meta.blogDescription}
        image={meta.siteUrl + meta.siteImage}
        twitterTitle={meta.siteTitle + " " + meta.blogName}
        facebookTitle={meta.siteTitle + " " + meta.blogName}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <header className="hero container-fluid pt-3 mt-5 pb-lg-4">
        <div className="row contained-xl justify-content-center">
          <div className="col-11 col-lg-5 text-center col-xl-5 mb-md-5 py-lg-5 ">
            <h1 className="">{meta.blogName}</h1>
            <div className="lead">{meta.blogDescription}</div>
          </div>
        </div>
      </header>

      <section className="postlists container-fluid">
        <div className="row justify-content-center bg-light negative-margin mt-4 ">
          <div className="col-md-12 col-xl-11 negative-margin_inner">
            <div className="row pb-5 contained-xl">
              {posts &&
                posts.map(function ({ node: post }, index) {
                  // if (index === 0 && pageContext.pageNumber === 0) {
                  //   return (
                  //     <div
                  //       className="col-12 mb-4 "
                  //       key={"block_" + pageContext.pageNumber + "_" + index}
                  //     >
                  //       <JournalCardLarge
                  //         featured_media={post.frontmatter.image}
                  //         title={post.frontmatter.title}
                  //         date={post.frontmatter.date}
                  //         excerpt={post.frontmatter.excerpt}
                  //         slug={post.fields.slug}
                  //         readtime={post.timeToRead}
                  //       />
                  //     </div>
                  //   );
                  // } else {
                    return (
                      <div
                        key={"block_" + pageContext.pageNumber + "_" + index}
                        className="col-md-6 col-lg-4 col-xl-3 mb-4"
                      >
                        <JournalCard
                          featured_media={post.frontmatter.image}
                          title={post.frontmatter.title}
                          date={post.frontmatter.date}
                          excerpt={post.frontmatter.excerpt}
                          slug={post.fields.slug}
                          readtime={post.timeToRead}
                        />
                      </div>
                    );
                  // }
                })}
            </div>
          </div>
        </div>
      </section>
      <Pagination pageContext={pageContext} pathPrefix="/journal" />
    </Layout>
  );
};

export default BlogPages;

export const blogPagesQuery = graphql`
  query blogPagesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "//blog//" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
            excerpt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
